:root {
  --apple-color: #000000;
  --primary: #2988ff;
}

body {
  overflow-x: auto;
  justify-content: center;
}

svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: baseline !important;
}

img {
  display: inline-block !important;
}

button {
  outline-width: 0;
}
/* ::-moz-selection {
  background: #f00;
  color: #000000;
} */
/* ::selection {
  background: #f00;
  color: #000000;
} */
.hide {
  display: none;
}

.sidemenu .app-log {
  padding: 3% 11.5% 1.5% 12%;
  cursor: pointer;
}
.sidemenu .sm-img {
  margin: auto;
  width: 60px;
  height: 60px;
  transition: width 0.2s, height 0.2s;
}
.header {
  background: white !important;
  border-bottom: 1px solid rgb(219, 219, 219);

  height: 60px;

  display: flex;
  align-items: center;

  padding: 0 20px !important;
}

.header .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.navigators-menu {
  font-size: 14px;
  /* font-weight: bold; */

  color: #444444;
  cursor: pointer;

  width: 100px;
  height: 36px;

  text-align: center;

  line-height: 36px;

  margin: 0 10px;
}

.navigators-menu:hover {
  background: #f4faff;
  color: #2988ff;
}

.header-manager-btn {
  color: #fff !important;
  background: #051a65 !important;
  border: 2px solid #051a65 !important;
}
.header-manager-btn:hover {
  color: #fff !important;
}
.drag-n-drop-image-loader {
  border: 1px dashed #d9d9d9;
  transition: 0.5s;
  text-align: center;
  margin: auto;
  background: #e6e6e6;
  position: relative;
}
.drag-n-drop-image-loader:hover {
  cursor: pointer;
  background-color: #d9d9d9;
  border: 1px dashed #000;
}
.drag-n-drop-image-loader:-moz-drag-over {
  background-color: #d9d9d9;
}
.drag-n-drop-image-loader img {
  max-width: 800px;
  max-height: 320px;
  width: 100%;
  object-fit: contain;
  margin: auto;
}
.drag-n-drop-image-loader .text-wrap {
  padding: 40px;
}
.drag-n-drop-image-loader .close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
}
@media (max-width: 1024px) {
  .drag-n-drop-image-loader img {
    max-width: none;
  }
}
@media (max-width: 480px) {
  .drag-n-drop-image-loader {
    max-height: inherit;
  }
}
.border-none-card > .ant-card-body {
  padding: 0;
}
.content-inner {
  padding: 24px;
  background: #fff;
  min-height: calc(100vh - 190px);
  overflow: auto;
}
@media (max-width: 1024px) {
  .content-inner {
    padding: 0;
  }
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ant-image-preview-img-wrapper {
  display: flex;
  align-items: center;
}
.ant-image-preview-img-wrapper img {
  margin: 0 auto;
}
.hover-effect {
  cursor: pointer;
}
.hover-effect :hover {
  color: #2cb69a;
}
.option-border {
  border-bottom: 1px solid lightgray;
  padding-top: 4px;
  padding-bottom: 4px;
}
.option-border:first-child {
  border-top: 1px solid lightgray;
}
.ql-editor {
  min-height: 200px;
}
.ant-descriptions-row .ant-descriptions-item-label {
  width: 20%;
}
/* .market-info-input-group span {
  padding: 5px 8px 0 0;
  border: none;
} */
.caution p {
  margin: 0;
  font-weight: 500;
  color: red;
}
.product-search-ul-tag div {
  background: #e4f0ff;
}
.product-search-ul-tag li {
  text-align: left;
  margin-left: 10px;
}
.service-pay-components {
  display: flex;
  width: 100%;
  overflow: auto;
  /* justify-content: space-between; */
  /* background: yellow; */
}
.service-pay-components .wrap {
  display: inline-block;
  height: max-content;
  border: 1px solid #e2e8f0;
  margin-right: 20px;
  text-align: center;
  min-width: 330px;
  border-radius: 5px;
}
.service-pay-components .title {
  background: #f8f8f8;
  color: #6e6e6e;
  font-size: 20px;
  font-weight: 700;
  padding: 21px 0;
  border-bottom: 1px solid #e6e6e6;
}
.service-pay-components .item-info {
  background: #2988ff;
  width: 227px;
  margin: 21px auto 23px auto;
  padding: 20px 0;
  line-height: 20px;
  color: #fff;
  font-weight: 500;
  border-radius: 4px;
}
.service-pay-components .price-info {
  color: #2988ff;
  font-size: 30px;
  font-weight: 700;
  width: 240px;
  margin: 0 auto 22px auto;
  border-bottom: 2px solid #e7e6e7;
}
.service-pay-components .price-info-text {
  color: #bababa;
  font-size: 18px;
  font-weight: 400;
  line-hight: 32px;
}
.service-pay-components .price-info-amplify-text {
  background: #555555;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  border-radius: 4px;
  margin-bottom: 17px;
  padding: 17px 0 26px 0;
}

/* 로딩 시작 */
.loading-container {
  display: flex;
  height: calc(100vh - 64px - 70px);
  justify-content: center;
  align-items: center;
}
.spinner {
  animation: rotator 1.4s linear infinite;
}
@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite;
  stroke: #39b978;
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}
/* 로딩 끝 */

/* 로그인 모달 공통 */
.account-title {
  font-size: 28px;
  margin-bottom: 30px;
}
.login-back:hover {
  color: #7d7d7d;
}
.count-down {
  font-size: 12px;
}

/* 로그인 모달 시작 */
.login-modal .ant-modal-content {
  /* border-radius: 15px; */
  width: 400px;
}
.login-modal .ant-modal-body {
  padding-left: 44px;
  padding-right: 44px;
  padding-bottom: 32px;
}
.login-modal .login-button {
  width: 100%;
  height: 44px;
  background: #2988ff;
  border: none;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  border-radius: 4px;
  outline-width: 0;
}

.login-modal .login-bottom .naver-button {
  background: #00c73c;
  height: 44px;
  border-radius: 4px;
  padding-left: 16px;
  padding-right: 16px;
  color: #fff;
  outline-width: 0;
  font-size: 16px;
  font-weight: 500;
}
.login-modal .login-bottom .kakao-button {
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background: #ffe812;
  height: 44px;
  outline-width: 0;
  font-size: 16px;
  font-weight: 500;
}
.login-modal .id-pw-button {
  outline-width: 0;
}
.login-modal .id-pw-button:hover {
  color: #7d7d7d;
}
.login-modal .join-button {
  outline-width: 0;
}
.login-modal .join-button:hover {
  color: #7d7d7d;
}
/* 로그인 모달 끝 */

/* 계정 찾기 모달 시작 */
.find-modal .ant-modal-content {
  border-radius: 15px;
  width: 400px;
}
.find-modal .ant-modal-body {
  padding-left: 44px;
  padding-right: 44px;
  padding-bottom: 32px;
}
.find-modal .find-top-id-button {
  height: 44px;
  outline-width: 0;
  border: 1px solid #d9d9d9;
}
.find-modal .find-top-pw-button {
  height: 44px;
  outline-width: 0;
  border: 1px solid #d9d9d9;
}
.find-modal .active {
  color: #fff;
  /* background: #7d7d7d; */
  border: none;
}
.find-modal .find-form {
  padding-top: 20px;
}
.find-modal .find-button {
  width: 100%;
  height: 44px;
  border: none;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  margin-top: 20px;
}

/* 계정 찾기 모달 끝 */

/* 회원가입 모달 시작 */
.join-modal .ant-modal-content {
  border-radius: 15px;
  width: 400px;
}
.join-modal .ant-modal-body {
  padding-left: 44px;
  padding-right: 44px;
  padding-bottom: 32px;
}

.join-modal .join-button {
  width: 100%;
  height: 44px;
  background: #00e0b4;
  border: none;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  border-radius: 4px;
  outline-width: 0;
}

/* 회원가입 모달 끝 */

/* 다음 모달 시작 */
.daum-modal .ant-modal-content {
  border-radius: 15px;
  width: 400px;
}
/* 다음 모달 끝 */

/* 마켓 관리 기본정보 시작 */
.user-market-info-modal .ant-modal-content {
  border-radius: 15px;
}
.user-market-info-Corporation td {
  padding-bottom: 0;
}
.info-social-login:focus {
  border: none;
  outline: none;
}
.go-product-page-btn {
  padding: 6px 15px 8px 15px;
  line-height: 32px;
  border: 1px solid #d9d9d9;
  background: #ffffff;
}
.ant-input-number-handler-wrap {
  display: none;
}
/* 마켓 관리 기본정보 끝 */

/* 스토어계정 목록 시작 */
.add-store-account-modal .ant-modal-content {
  border-radius: 15px;
}
/* 스토어계정 목록 끝 */

/* 상품수집 시작 */
.collection-card > .ant-card-head {
  min-height: 40px;
}
.collection-card > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 0;
}
.collection-card > .ant-card-body {
  padding: 12px 24px;
}
/* 상품수집 끝 */

/* 상품관리 상품목록 시작*/
.product-list-checkbox {
  position: absolute;
}
.product-list-checkbox .ant-checkbox-inner {
  border-radius: 4px;
  width: 23px;
  height: 23px;
}
.ant-checkbox-inner::after {
  width: 5px;
  height: 15px;
}
.product-list-item-title {
  display: inline-block;
  margin-top: 15px;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 라인수 */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  line-height: 1.2em;
  height: 2.6em; /* line-height 가 1.2em 이고 3라인을 자르기 때문에 height는 1.2em * 3 = 3.6em */
}
.product-list-item-price {
  font-weight: 600;
  margin-bottom: 10px;
}
.ant-table-row {
  cursor: pointer;
}
.ant-table-taobaoNumIid {
  cursor: text;
  color: #1890ff;
  text-decoration: underline;
}
.ant-table-taobaoNumIid:hover {
  color: #2988ff;
  text-decoration: underline;
}
.taps-label-style {
  width: 140px;
  height: 35px;
  line-height: 35px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}
.taps-label-number {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

/* 디테일 */
.ant-collapse > .detail-page-panel > .ant-collapse-header .ant-collapse-arrow {
  padding: 9px 0 0 0;
}
.braft-editor > .bf-content {
  height: 600px;
}
.ant-collapse > .detail-page-panel > .ant-collapse-header {
  padding: 8px 48px;
}
.detail-page-panel > .ant-collapse-content.ant-collapse-content-active {
  max-height: 300px;
  overflow-y: overlay;
}

/* 테이블 크기 줄이기용 */
.ant-table-column-sorters {
  padding: 10px 5px;
}
.ant-table-thead > tr > th {
  padding: 10px 5px;
}
.ant-table-tbody > tr > td {
  padding: 10px 5px;
}
.table-detail-button {
  color: #fff;
  background: #2988ff;

  padding: 4px 17px 7px 19px;
  border-radius: 2px;
}
.table-detail-button:hover {
  color: #fff;
  background: #7585ff;
}
/* 상품관리 상품목록 끝*/

/* 랜딩페이지 시작 */
.landing-page-form {
  background: white;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 10px;
}
.lading-page-slick-item {
  height: max-content;
  color: #ffffff;
  background: white;
  /* width: 99.24vw; */
  min-width: 1350px;
  height: 700px;
}
.slick-list {
  width: 99.24vw;
  min-width: 1350px;
}
.lading-page-slick-item > .slick-prev {
  left: 10%;
  width: 12.5px;
  height: 24px;
  z-index: 10;
}
.lading-page-slick-item > .slick-next {
  right: 10%;
  width: 12.5px;
  height: 24px;
  z-index: 10;
}
@media (max-width: 1500px) {
  .lading-page-slick-item > .slick-prev {
    left: 6%;
  }
  .lading-page-slick-item > .slick-next {
    right: 6%;
  }
}
.ladingpage-slick-btn {
  margin-top: 50px;
  height: 77px;
  background: #2988ff;
  color: #fff;
  border-radius: 10px;
  font-size: 26px;
  font-weight: 700;
  padding: 0 50px;
}
.ladingpage-slick-btn:focus {
  outline: none;
}

.landing-form1 {
  background: ghostwhite;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 10px;
  margin: 0px 10px 10px 10px;
  font-weight: 500;
  color: #34373b;
  padding: 10px;
}
.landing-form1-img {
  display: inline-block;
  margin-right: 10px;
}
.landing-form1-text {
  color: #2988ff;
}

.landing-form2 {
  width: 173px;
  padding: 13px 0;
  background: #2988ff;
  border-radius: 26px;
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
}
.landing-form3 {
  width: 350px;
  font-weight: 500;
  font-size: 30px;
  line-height: 43px;
  text-align: center;
}
.lading-from4-underline {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 55%, rgba(41, 136, 255, 0.3) 45%);
}

.info-download-btn {
  display: flex;
  font-size: 24px;
  fontweight: 500;
  line-height: 32px;
  width: max-content;
  color: #2988ff;
  border: 2px solid #2988ff;
  border-radius: 11px;
  margin-top: 15px;
  padding: 10px 25px;
  cursor: pointer;
}

.question-btn:focus {
  border: none;
  outline: none;
}

/* 랜딩페이지 끝 */
.add-product-data-button {
  padding: 5px 15px;
  background: #fff;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  transition-duration: 0.3s;
}
.add-product-data-button:hover {
  color: #2988ff;
  border: 1px solid #2988ff;
  transition-duration: 0.3s;
}

/* 상품 상세보기 */
.product-detail-container {
  font-size: 16px;
  font-weight: 500;
  padding: 5px 0;
  border-bottom: 1px solid #d9d9d9;
}
.product-detail-title {
  display: inline-block;
  width: 150px;
}
.tabs-table {
  text-align: center;
  border: 1px solid #e0e0e0;
  border-collapse: collapse;
}
.tabs-table body {
  height: 200px;
  overflow-y: auto;
}
.tabs-table-btn {
  width: 50px;
  height: 25px;
  border-radius: 10px;
}
.tabs-table-btn:focus {
  outline: none;
}
.won-span {
  border: 1px solid #d9d9d9;
  height: 35px;
  font-size: 16px;
  display: inline-block;
}
.won-span:hover {
  border: 1px solid #2988ff;
}
.category-search-table > tr:hover {
  background: #2988ff;
  color: #ffffff;
}
.ant-form-item {
  margin-bottom: 0;
}
.thumbnail-image-x-btn {
  width: 35px;
  position: relative;
  background: #2988ff;
  top: 55px;
  left: 324px;
  margin-top: -32px;
  cursor: pointer;
}
.thumbnail-image-x-btn:hover {
  background: #1890ff;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 7px;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 15px;
  height: 100%;
  cursor: col-resize;
}

/* 주문 목록 */
.oredr-list-table
  > .ant-spin-nested-loading
  > .ant-spin-container
  > .ant-table
  > .ant-table-container
  > .ant-table-content
  > table
  > .ant-table-tbody
  > .ant-table-row {
  cursor: default;
}

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 0px 8px;
}

.optionsInput {
  padding: 0 11px;
}

/* 결제관리 이용료결제 */
.service-pay-card > .ant-card-body {
  padding: 8px 24px;
}

.button-width109 {
  width: 109px;
}
.naver-icon-style-at-customer {
  display: inline-block;
  width: 22px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: #00c73c;
  margin-right: 4px;
}
.kakao-icon-style-at-customer {
  display: inline-block;
  width: 22px;
  height: 22px;
  background: #ffe812;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
}
/* .imageTranslatTrue {
  background: #f0f4ff;
}
.ant-table-row-selected > td {
  background: #ccdaff !important;
}
.imageTranslatFalse {
  background: #ffd4d4;
} */

/* 마켓관리 - 기본정보 한인식 */
.user-market-info-description .ant-descriptions-item-label {
  padding-top: 0px;
  padding-bottom: 0px;
}
.user-market-info-description .ant-descriptions-item-content {
  padding-top: 0px;
  padding-bottom: 0px;
}
/* .user-market-info-company .ant-descriptions-item-label {
} */
.user-market-info-company .ant-descriptions-item-content {
  padding-right: 0px;
  padding-left: 0px;
}

.user-market-site-info-ship,
.user-market-site-info-ship > div,
.user-market-site-info-ship > div > table,
.user-market-site-info-ship .ant-descriptions-item-container {
  height: 100%;
}
.user-market-site-info-ship .ant-descriptions-item-label,
.user-market-site-info-ship .ant-descriptions-item-content {
  height: 100%;
  align-items: center;
}

.normal-tab-menu > li:nth-child(1) {
  border-right: 1px solid #dddddd;
}
.normal-tab-menu > li.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: #2988ff;
}

/* 오픈마켓 계정목록 */
.info-account-grid span {
  background-color: #449ee2;
  color: white;
  font-weight: 900;
  font-size: 18px;
  padding: 2px 0;
}

/* 서비스 결제 */
.service-pay-container h1 {
  font-size: 32px;
  font-weight: 900;
}
.service-pay-container h2 {
  font-size: 28px;
  font-weight: 900;
}
.service-pay-container p {
  font-size: 18px;
  font-weight: 900;
  line-height: 2;
  white-space: pre;
}
.service-pay-container button {
  background-color: #449ee2;
  color: white;
  width: 160px;
  font-size: 1.5rem;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 0.8rem;
}
.service-pay-container button:focus {
  outline: none;
}
.service-pay-container a {
  color: inherit;
}
.service-pay-container a:hover {
  color: inherit;
}
