@font-face {
  font-family: "NNSQUAREROUNDR";
  src: url("./assets/fonts/NanumSquareRoundR.ttf");
}

html * {
  font-family: 'NNSQUAREROUNDR' !important;
}

body {
  margin: 0;
  
  /* font-family: sans-serif; */
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}